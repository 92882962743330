<template>
  <div
    class="container size-mobile bg-element-white p-0 min-height-second paket"
    style="font-family: Montserrat"
  >
  
    <v-dialog/>
    <alert-error ref="ae"/>
    <date-picker v-model="startDate" valueType="format"></date-picker>
      <input type="text" class="form-control" style="border-radius: 0 !important;" placeholder="Cari Paket" v-model="search"/>
    <div class="container mt-4" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="1" />
        <content-placeholders-img />
        <content-placeholders-text :lines="2" />
        <content-placeholders-text :lines="1" />
      </content-placeholders>
    </div>
    <div v-else>
      <!--     
    <div class="container" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-img /> 
      </content-placeholders> 
      </div>-->
      
      <div v-if="filteredData.length > 0" class="table-responsive text-center" style="font-size: 12px;font-family:roboto">
        <table v-if="datas" class="table table-borderless table-striped table-hover mt-3">
          <thead>
            <tr>
              <th scope="col">No Resi</th>
              <th scope="col">Pengirim</th>
              <th scope="col">Penerima</th>
              <th scope="col">Diambil</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in filteredData"
              :key="data.id"
              id="edit-item"
              class="data-row edit-item-trigger-clicked"
              @click="showModal(data)"
              data-item-id="1"
            >
              <td class="no_resi" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;max-width: 100px;">{{data.no_resi}}</td>
              <td class="pengirim">{{data.pengirim}}</td>
              <td class="penerima">{{data.penerima}}</td>
              <!-- <td class="status" data-status="1" data-jenis="barang">
                <label class="badge badge-success mb-0">Sudah</label>
              </td> -->
              <td class="status">
                <label class="badge mb-0" :class="(data.status_diambil == 1 ? 'badge-success' : 'badge-danger')">{{data.status_diambil == 1 ? 'Sudah' : 'Belum'}}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container" v-else>
        <div class="text-date d-flex bg-highlight justify-content-center bg-light rounded p-3"> Tidak ada paket pada tanggal ini</div>
      </div> 
      
      <!-- Modal  -->
      <modal v-show="isModalVisible" @close="closeModal" style="z-index: 99">
        <!-- Override Modal Header -->
        <template v-slot:header><b style="color: #2b80ac">Ubah Paket</b></template>

        <!-- Override Modal body -->
        <template v-slot:body>
          <form id="edit-form" class="form-horizontal" method="POST" @submit.prevent="updatePaket(dataModal.id)" enctype="multipart/form-data">
            <div class="mb-1">
              <legend class="legend-input">Jenis Paket</legend>
              <div class="d-flex mt-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="jenis"
                    value="barang"
                    v-model="dataModal.jenis_paket"
                  />
                  <label class="form-check-label">Barang</label>
                </div>
                <div class="form-check ml-4">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="jenis"
                    value="dokumen"
                    v-model="dataModal.jenis_paket"
                  />
                  <label class="form-check-label">Dokumen</label>
                </div>
              </div>
            </div>
            <fieldset class>
              <legend class="legend-input">Pengirim</legend>
              <input
                type="text"
                name="modal-input-pengirim"
                id="modal-input-pengirim"
                class="form-control"
                autocomplete="off"
                v-model="dataModal.pengirim"
                required="required"
              />
            </fieldset>
            <fieldset class>
              <legend class="legend-input">Penerima</legend>
              <input
                type="text"
                name="modal-input-penerima"
                id="modal-input-penerima"
                class="form-control"
                autocomplete="off"
                v-model="dataModal.penerima"
                required="required"
              />
            </fieldset>
            <fieldset class>
              <legend class="legend-input">No. Resi</legend>
              <input
                type="text"
                name="modal-input-resi"
                id="modal-input-resi"
                class="form-control"
                autocomplete="off"
                v-model="dataModal.no_resi"
                required="required"
              />
            </fieldset>
            <!-- <fieldset class>
              <legend class="legend-input">Ditujukan Untuk</legend>
              <input
                type="text"
                name="ditujukan"
                id="ditujukan"
                class="form-control"
                autocomplete="off"
                v-model="dataModal.bagian_penerima"
                required="required"
              />
            </fieldset> -->
            <div class="mb-1">
              <legend class="legend-input mt-3">Status Diambil</legend>
              <div class="d-flex mt-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="status"
                    value="1"
                    v-model="dataModal.status_diambil"
                  />
                  <label class="form-check-label" for="status">Sudah</label>
                </div>
                <div class="form-check ml-4">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="status"
                    value="0"
                    v-model="dataModal.status_diambil"
                  />
                  <label class="form-check-label" for="exampleRadios2">Belum</label>
                </div>
              </div>
            </div> 
            <fieldset class="mt-2">
              <legend class="legend-input">Nama Pengambil</legend>
              <input
                type="text"
                name="modal-input-pengambil"
                id="modal-input-pengambil"
                class="form-control"
                autocomplete="off"
                v-model="dataModal.nama_pengambil"
                required="required"
              />
            </fieldset>
            <fieldset class="mt-2">
              <legend class="legend-input">Tanggal Diambil</legend> 
              <input
                type="date"
                name="tanggal-diambil"
                id="tanggal-diambil"
                class="form-control"
                autocomplete="off" 
                v-model="dataModal.diambil_pada_tanggal"
              />
            </fieldset>
            
            <div class="d-flex flex-column bd-highlight mt-3" @click="modalShowImg()">
                <span class="mb-3 text-4">Bukti Foto</span>
                <img v-bind:src="img" class="foto-absen" />
            </div>
            <button class="btn-blue mt-4" style="width:100%" :class="{'disable': isLoadingUpload}"  type="submit">
              <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                  <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                Sedang Upload...
              </span>
              <span v-else>Simpan</span>
            </button> 
            <span class="btn btn-danger mt-3" style="width:100%" @click="modalDelete(dataModal.id)" :class="{'disable': isSubmit}" type="submit"><b>Hapus</b></span> 
          </form>
        </template>
      </modal>

      <!-- Modal Img -->
       <modal v-show="isModalVisibleImg" @close="closeModalImg">
        <!-- Override Modal Header -->
        <template v-slot:header>Unggah Foto</template>
        
        <!-- Override Modal body -->
        <template v-slot:body>
          <form class="uploadFile d-flex flex-column justify-content-center"  @submit.prevent="uploadSubmit()" enctype="multipart/form-data" method="post" style="position:absolute;bottom:0;width:95%;height:100%" >
              <div id="preview" class="d-flex align-items-center justify-content-center mb-2" style="height:80%;">
                <img v-if="imgUploaded" :src="imgUploaded" style="object-fit: contain;width: 100%;height: 100%;text-align:center"/>
              </div>
              <a class="btn btn-daftar" href="" style="width: auto !important;">
                  <span class="" style="float:left;margin-right:-15px;margin-top:-3px"><img src="../../assets/img/cam.png" alt="Attach" style="width:25px;"></span>
                  Ambil Gambar
                  <input id="file" name="file" type='file' class="attach" accept="image/*" capture  @change="onFileChange"/>
              </a>
              <button v-if="imgUploaded" :disabled="isLoadingUpload" id="submit" class="btn btn-login mt-1" style="width: auto !important;">
                <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                    <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  Sedang Upload...
                </span>
                <span v-else>Upload</span>
                
              </button>
              <div class="progress mt-1">
                        <!-- PROGRESS BAR DENGAN VALUE NYA KITA DAPATKAN DARI VARIABLE progressBar -->
                <div class="progress-bar" role="progressbar" 
                    :style="{width: progressBar + '%'}" 
                    :aria-valuenow="progressBar" 
                    aria-valuemin="0" 
                    aria-valuemax="100"></div>
              </div>
          </form>
        </template>
      </modal>
    </div>
    <div class="d-flex">
      <router-link class="float" to="/paket/add">
        <i class="fa fa-plus my-float"></i>
      </router-link>
      <span class="float" @click="load()" style="background-color:#e0e0e0;color:#000;right:90px !important">
        <i class="fa fa-refresh my-float"></i>
      </span>
    </div>
  </div>
</template>
 
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import $ from "jquery";
// import _ from "lodash";
import modal from "../../components/Modal.vue";
import Api from "../../Api";
import alertError from "../../components/AlertError.vue";

export default {
  name: "paket",
  components: {
    DatePicker,
    modal,
    alertError
  },
  
  data() {
    var currentdate = new Date(); 
    var datetime = currentdate.getFullYear() + "-"
            + ('0' + (currentdate.getMonth()+1)).slice(-2)  + "-" 
            + ('0' + (currentdate.getDate())).slice(-2)
    return {
      // startDate: new Date()
      //   .toJSON()
      //   .slice(0, 10)
      //   .replace(/-/g, "-"),
      startDate:datetime,
      isLoading: true,
      listImg: '',
      datas: [],
      search:'',
      isLoadingUpload:false,
      total: 0,
      dataModal: [],
      dataImg: '',
      imgUploaded:'',
      isModalVisible: false,
      isModalVisibleImg: false,
      updatedContent: "",
      token: '',
      progressBar:'',
      myData: '',
      file_upload: '',
      isSubmit: false,
      dataFiltered: 0,
      img: require('../../assets/img/upload.png'),
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem('token'))
    this.myData = JSON.parse(localStorage.getItem('cred'))
    this.load();
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: {
    async load() {
      this.isLoading = true,
      await Api.get("satpam/paket/"+this.myData.id_tempat_kerja,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          this.datas = response.data.data;
          
          // console.log(this.datas)
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
    },
    async updatePaket(id){
      this.isLoadingUpload = true
      const paket = {
        user_id: this.myData.id,
        workplace_id: this.myData.id_tempat_kerja,
        no_resi: this.dataModal.no_resi,
        jenis_paket: this.dataModal.jenis_paket,
        pengirim: this.dataModal.pengirim,
        penerima: this.dataModal.penerima,
        nama_pengambil: this.dataModal.nama_pengambil,
        status_diambil: this.dataModal.status_diambil,
        diambil_pada: this.dataModal.diambil_pada_tanggal,
        // bagian_penerima: this.dataModal.bagian_penerima, 
      }
      console.log(paket)
      await Api.put("satpam/paket/"+id,paket,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
        this.closeModal()
        this.load()
        console.log(response) 
      })
      .catch((error) => {

        console.log(error);
        this.$refs.ae.showAlert()
      });
      this.isLoadingUpload = false 
    }, 
    async detailPaket(id){
      await Api.get("satpam/paket/detail/"+id,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          // console.log(response)
          this.dataImg = response.data.data.attachments
          if(this.dataImg.length > 0){
            this.img = this.dataImg[0].file_url
          }else{
            this.img = require('../../assets/img/upload.png')
          }
          // this.datas = response.data.data;
          // console.log(this.datas)
          // this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
    },
    showModal(data) {
      this.detailPaket(data.id)
      // console.log(data) 
      this.dataModal = data; 
      // this.updatedContent = data.pengirim
      this.isModalVisible = true;
    },
    // Modal show img per id
    modalShowImg() {
      
      // this.typeSubmit = type
      this.imgUploaded = this.img
      // this.attachment_id = this.todos[id].id
      // this.indexImg = id
      // this.idImg = id;
      this.isModalVisibleImg = true 
    },
    closeModal() { 
      this.imgUploaded = ''
      this.isModalVisible = false;
    },
    modalDelete(id){
      this.$modal.show('dialog', {
        title: 'Yakin Hapus Paket ?',
        text: 'Data yang telah dihapus tidak dapat dikembalikan',
        buttons: [
          {
            title: 'Tutup',
            handler: () => {
              this.$modal.hide('dialog')
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
          {
            title: 'Hapus',
            handler: () => {
              Api.delete("satpam/paket/"+id,{
                headers: {Authorization: 'Bearer ' + this.token}
              })
              .then(() => {
                this.closeModal()
                this.$modal.hide('dialog')
                this.load()
              })
              .catch((error) => {

                console.log(error);
                this.$refs.ae.showAlert()
              });
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
        ]
      })
    },
    // onchange ketika gambar dipilih
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.file_upload = e.target.files[0] 
    },
    // close modal img
    closeModalImg() {
      this.progressBar =0
      this.isModalVisibleImg = false;
    },
    //MENGIRIM FILE UNTUK DI-UPLOAD
    async uploadSubmit() {
        
        this.isLoadingUpload = true
 
        let formData = new FormData();
        formData.append('paket_id', this.dataModal.id)
        formData.append('file_upload', this.file_upload)
        formData.append('post_type', 'satpam_paket')
        
        await Api.post('satpam/paket/upload-foto', formData, {
            headers: {
              Authorization: 'Bearer ' + this.token, 
              'Content-Type': 'multipart/form-data'
              
            },
            onUploadProgress: function( progressEvent ) {
                //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
                this.progressBar = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }.bind(this)
        })
        .then((response) => {
            this.img = response.data.data.file_url
            console.log(response)
            // Jika berhasil upload gambar
            this.closeModalImg() 
            this.isLoadingUpload = false
        }).catch((error) => {
          console.log(error)
        })
        
    },

  },
  // Untuk Search Paket
  computed: {
    filteredData() {
      var vm = this;
      var startDate = vm.startDate;
      // console.log(this.datas)
      return this.datas.filter(item => {

        var date = item.tanggal;
        if (date == startDate) {
            if(this.search){
              return item.no_resi.toLowerCase().includes(this.search.toLowerCase()) || item.penerima.toLowerCase().includes(this.search.toLowerCase()) || item.pengirim.toLowerCase().includes(this.search.toLowerCase())
            }
          // console.log(item.length)
          return date == startDate;
        } 

        // return post.title.toLowerCase().includes(this.search.toLowerCase())

      });
    }
  }
};
</script> 
<style>
.table th, .table td {
    padding: 0.5rem !important;
    vertical-align: middle !important;
}
</style>